<template>
  <div class="col-md-6 ml-auto mr-auto">
     <form @submit.prevent="validate('frm_changepwd')">
      <card class="card-login card-plain">
        <div slot="header">
          <div class="logo-container">
            <img src="img/logo.png" alt="">
          </div>
        </div>

        <div>
                <fg-input v-model="model.username"
                    :label="t('Username')"
                    :data-vv-as="t('username')"
                    required
                    name="username"
                    :error="getError('username')"
                    autocapitalize="none">
                </fg-input>

                <fg-input :label="t('Current password')"
                    :data-vv-as="t('current password')"
                    required
                    type="password"
                    name="currentPassword"
                    v-validate="modelValidations.currentPassword"
                    :error="getError('currentPassword')"
                    v-model="model.currentPassword">
                </fg-input>

                <fg-input :label="t('New password')"
                    :data-vv-as="t('new password')"
                    required
                    type="password"
                    name="newPassword"
                    ref="newPassword"
                    v-validate="modelValidations.newPassword"
                    :error="getError('newPassword')"
                    v-model="model.newPassword">
                </fg-input>

                <fg-input :label="t('Confirm password')"
                    :data-vv-as="t('confirm password')"
                    required
                    type="password"
                    name="newPasswordConfirm"
                    v-validate="modelValidations.newPasswordConfirm"
                    :error="getError('newPasswordConfirm')"
                    v-model="model.newPasswordConfirm">
                </fg-input>
        </div>
        <div class="category form-category">* {{ t('Required fields') }}</div>
        <div slot="footer">
          <n-button native-type="submit" type="primary" round block>
            {{ t('Change') }}
          </n-button>

        </div>

      </card>
    </form>
  </div>
</template>

<script>
export default {
  name: 'force-change-password',
    data() {
        return {
            model: {
                username: '',
                currentPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
            },
            modelValidations: {
                username:{
                    required: true,
                },
                currentPassword: {
                    required: true,
                },
                newPassword: {
                    required: true,
                },
                newPasswordConfirm: {
                    required: true,
                    confirmed: 'newPassword',
                },
            },
        };
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        async validate() {
            let isValid = await this.$validator.validateAll();
            if (isValid){
                let data = {  "username" : this.model.username,
                        "previous_password": this.model.currentPassword,
                        "new_password": this.model.newPassword,
                }
                let ret = await this.$store.dispatch('setPassword', data);
                this.model.currentPassword = this.model.newPassword = this.model.newPasswordConfirm = '';
                this.$validator.reset();
                this.$store.dispatch("auth/login");
                // if (ret)
                //     setTimeout(() => {
                //         this.$store.dispatch("login");
                //     }, 100);
            }
        },
    },
};
</script>

<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
div.no-height, .no-height {
  height: initial !important
}
input.form-control{
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
<template>
    <div class="col-md-6 ml-auto mr-auto">
        <card class="card-login card-plain">

            <div slot="header">
                <div class="logo-container">
                    <img src="img/logo.png" alt="">
                </div>                
            </div>
            <div>
                <n-button native-type="submit" type="primary" round block @click.native.prevent="sms()">
                    {{ t('Recover by SMS') }}
                </n-button>
                <n-button native-type="submit" type="primary" round block @click.native.prevent="email">
                    {{ t('Recover by e-mail') }}
                </n-button>

            </div>
        </card>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'recoverPassword',
    data() {
        return {
            model: {
                recoveryInfo: ''
            }
        }
    },
    methods:{
        sms(){
            this.$store.dispatch('resetPassword/setRecoveryMethod', 'sms');
            this.$store.dispatch('resetPassword/resetPasswordRequest', {
                restablish: {
                    method_name: 'sms', 
                    username: this.$store.getters['resetPassword/getUsername']
                }
                });            
        },
        email(){
            this.$store.dispatch('resetPassword/setRecoveryMethod', 'email');
            this.$store.dispatch('resetPassword/resetPasswordRequest', 
            {
                restablish: {
                    method_name: 'email',
                    username: this.$store.getters['resetPassword/getUsername']
                }
                });   
        }
    },
}
</script>

<style>

</style>
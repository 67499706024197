<template>
  <div class="col-md-6 ml-auto mr-auto">
    <form @submit.prevent="updateExpiredPassword">
      <card class="card-login card-plain">

        <div slot="header">
          <div class="logo-container">
            <img src="img/logo.png" alt="">
          </div>
          <div class="title-text">
            {{ t('As security policy, you are requested to update your password. Please provide a new password with the form below.')}}
          </div>
        </div>

        <div>
              <fg-input :placeholder="t('Current password')"
                  :data-vv-as="t('current password')"
                  required
                  type="password"
                  name="currentPassword"
                  v-validate="modelValidations.currentPassword"
                  class="no-border no-height form-control-lg"
                  :error="getError('currentPassword')"
                  data-vv-validate-on="blur"
                  addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                  v-model="model.currentPassword">
              </fg-input>

              <fg-input :placeholder="t('New password')"
                  :data-vv-as="t('new password')"
                  required
                  type="password"
                  name="newPassword"
                  ref="newPassword"
                  v-validate="modelValidations.newPassword"
                  class="no-border no-height form-control-lg"
                  :error="getError('newPassword')"
                  data-vv-validate-on="blur"
                  addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                  v-model="model.newPassword">
              </fg-input>

              <fg-input :placeholder="t('Confirm password')"
                  :data-vv-as="t('confirm password')"
                  required
                  type="password"
                  name="newPasswordConfirm"
                  v-validate="modelValidations.newPasswordConfirm"
                  class="no-border no-height form-control-lg"
                  :error="getError('newPasswordConfirm')"
                  data-vv-validate-on="blur"
                  addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                  v-model="model.newPasswordConfirm">
              </fg-input>

          <b-row no-gutters v-if="isLoginCaptchaEnabled">
            <b-col cols="12" >
              <fg-input v-model="model.captcha" autocomplete="off"
                        id="captcha"
                        v-validate="'required'"
                        type="text"
                        name="captcha"
                        :error="getError('captcha')"
                        class="no-border no-height form-control-lg"
                        placeholder="Captcha"
                        addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                        autocapitalize="none"
                        style="font-size:1rem;">
              </fg-input>
            </b-col>
            <b-col lg="3" md="1">
            </b-col>
            <b-col lg="9" md="11" class="ml-auto">
              <audio ref="sound" :src="captcha.captcha_audio" v-if="isAudioCaptchaAvailable">
                Your browser does not support the <code>audio</code> element.
              </audio>
              <img :src="captcha.captcha_img" :alt="captcha.captcha_hash" />
              <n-button @click.native="handleRefresh" round>
                <i class="now-ui-icons arrows-1_refresh-69"></i>
              </n-button>
              <n-button @click.native="handleSound" round v-if="isAudioCaptchaAvailable">
                <i class="now-ui-icons media-2_note-03"></i>
              </n-button>
            </b-col>
          </b-row>
        </div>

        <hr style="border-top: 0px"/>
        <div v-if="error_response" class="category form-category">
            <div v-if="hasPasswordConditions">
                {{ t('identity-card.must_include') }}
            </div>
            <div v-if="checkIncludeChar">
                - {{ password_complexity.minimum_password_length }} {{ t('identity-card.must_include_char') }}
            </div>
            <div v-if="checkIncludeNumb">
                - {{ password_complexity.minimum_number_digits_required }} {{ t('identity-card.must_include_numb') }}
            </div>
            <div v-if="checkIncludeUpp">
                - {{ password_complexity.minimum_uppercase_required }} {{ t('identity-card.must_include_upp') }}
            </div>
            <div v-if="checkIncludeLow">
                - {{ password_complexity.minimum_lowercase_required }} {{ t('identity-card.must_include_low') }}
            </div>
            <div v-if="checkIncludeSym">
                - {{ password_complexity.minimum_symbols_required }} {{ t('identity-card.must_include_sym') }}
            </div>
            <div v-if='checkIncludeMidNum'>
                - {{ password_complexity.minimum_middle_number_digits_required }} {{ t('identity-card.must_include_mid_num') }}
            </div>
            <div v-if='checkIncludeMidSym'>
                - {{ password_complexity.minimum_middle_symbols_required }} {{ t('identity-card.must_include_mid_sym') }}
            </div>
            <div v-if="checkIncludeDiff">
                - {{ getDiffMessage }}
            </div>
                        
            <hr style="border-top: 0px"/>

            <span v-if="hasAdditionalPasswordCondition">{{ t('identity-card.password_addition') }}</span>
            <div v-if="password_complexity.check_keyboard_pattern">
              - {{ t('identity-card.check_keyboard_pattern') }}
            </div>
            <div v-if="password_complexity.check_mirrored_sequence">
              - {{ t('identity-card.check_mirrored_sequence') }}
            </div>
            <div v-if="password_complexity.check_repeated_sequence">
              - {{ t('identity-card.check_repeated_sequence') }}
            </div>
            <div v-if="password_complexity.check_sequential_letters">
              - {{ t('identity-card.check_sequential_letters') }}
            </div>
            <div v-if="password_complexity.check_sequential_numbers">
              - {{ t('identity-card.check_sequential_numbers') }}
            </div>
        </div>
        <hr style="border-top: 0px"/>
        <div class="category form-category" 
            :class="[{green: error_response == 'IDENTITY.OK'}, {red: error_response != 'IDENTITY.OK'}]" 
            v-if="error_response">
            {{ t(error_response) }}
        </div>


        <div slot="footer">
          <n-button native-type="submit" type="primary" id="btn_submit" round block>
            {{ t('Update Password') }}
          </n-button>
          <div class="pull-left">
            <h6>
              <router-link class="link footer-link" to="/forgotmypassword">
                {{ t('Forgot my Password') }}
              </router-link>
            </h6>
          </div>

        <div class="pull-right">
          <h6>
            <router-link class="link footer-link" to="/forgotmyusername">
                {{ t('Forgot my Username') }}
            </router-link>
          </h6>
        </div>

        </div>
      </card>
    </form>
  </div>
</template>
<script>
export default {
  name: 'expired-password',
  data() {
    return {
        model: {
            username: '',
            captcha: '',
            currentPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
        },
        modelValidations: {
            currentPassword: {
                required: true,
            },
            newPassword: {
                required: true,
            },
            newPasswordConfirm: {
                required: true,
                confirmed: 'newPassword',
            },
        },
        password_complexity: {
            minimum_password_length: 0,
            minimum_number_digits_required: 0,
            minimum_uppercase_required: 0,
            minimum_lowercase_required: 0,
            minimum_symbols_required: 0,
            minimum_middle_number_digits_required: 0,
            minimum_middle_symbols_required: 0,
            history_check_depth: 0
        },
        error_response: null
    };
  },
  computed: {
    captcha() {
      return this.$store.getters["auth/captcha"];
    },
    isLoginCaptchaEnabled() {
      const captcha = this.captcha;

      return ('login_captcha_enabled' in captcha) && (captcha.login_captcha_enabled === true);
    },
    isAudioCaptchaAvailable(){
      return !!this.captcha && !!this.captcha.captcha_audio
    },
    hasPasswordConditions() {
        return (this.checkIncludeChar || this.checkIncludeNumb || this.checkIncludeUpp
            || this.checkIncludeLow || this.checkIncludeSym || this.checkIncludeMidNum
            || this.checkIncludeMidSym || this.checkIncludeDiff)
    },
    hasAdditionalPasswordCondition() {
      return this.password_complexity && 
      (!!this.password_complexity.check_keyboard_pattern  || !!this.password_complexity.check_mirrored_sequence || !!this.password_complexity.check_repeated_sequence || !!this.password_complexity.check_sequential_letters || !!this.password_complexity.check_sequential_numbers)
    },
    checkIncludeChar: function() { 
        return this.password_complexity.minimum_password_length != 0;
    },
    checkIncludeNumb: function() { 
        return this.password_complexity.minimum_number_digits_required != 0;
    },
    checkIncludeUpp: function() { 
        return this.password_complexity.minimum_uppercase_required != 0;
    },
    checkIncludeLow: function() { 
        return this.password_complexity.minimum_lowercase_required != 0;
    },
    checkIncludeSym: function() { 
        return this.password_complexity.minimum_symbols_required != 0;
    },
    checkIncludeMidNum(){
        return this.password_complexity.minimum_middle_number_digits_required != 0;
    },
    checkIncludeMidSym(){
        return this.password_complexity.minimum_middle_symbols_required != 0;
    },
    checkIncludeDiff: function() { 
        return this.password_complexity.history_check_depth != 0;
    },
    getDiffMessage() {
      let historyCheck = this.password_complexity.history_check_depth;
      return historyCheck === 1
        ? this.t('identity-card.must_include_diff_current')
        : `${this.t('identity-card.must_include_diff_1')} ${historyCheck} ${this.t('identity-card.must_include_diff_2')}`
    },

  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async updateExpiredPassword() {
      let isValidForm = await this.$validator.validateAll();

      if (isValidForm) {
        let payload = {
          username: this.model.username,
          previous_password: this.model.currentPassword,
          new_password: this.model.newPassword,
          captcha: {
            captcha_response: this.model.captcha,
            captcha_hash: this.captcha.captcha_hash,
          },
        };
        let _vm = this
        this.$store.dispatch('auth/renewPassword', payload)
        .then( () => {
          this.$router.replace( {name: "Login"})
          this.$nextTick( () => {
            this.$store.commit('setSuccess', _vm.t('Password updated sucessfully'));
          });
        })
        .catch( err => {
            if (!!err){
              if (!!err.check_errors){
                _vm.error_response = err.type
                _vm.password_complexity = err.check_errors.password_complexity;
                _vm.password_complexity.history_check_depth = err.check_errors.password_rotation.history_check_depth;
                _vm.modelValidations.newPassword.min = err.check_errors.password_complexity.minimum_password_length;
              }
              if (err.refreshCaptcha){
                this.$store.dispatch('auth/getCaptcha', {});
                this.$nextTick( () => {
                  this.model.captcha = ''
                })
              }
            }
        })
      }
    },
    handleSound() {
      this.$refs['sound'].play();
    },
    handleRefresh() {
      this.$store.dispatch('auth/getCaptcha');
    },
  },
  mounted () {
    this.model.username = this.$store.getters["auth/id"];

    this.$store.dispatch('auth/getCaptcha');
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
div.no-height, .no-height {
  height: initial !important
}

.red {
  color: red ! important;
}
.green {
  color: green ! important;
}
</style>
<template>
    <div>
    </div>
</template>

<script>
export default {
    methods:{
        validateMagicLink(){
            this.$store.dispatch('resetPassword/setRecoveryMethod', 'email');
            this.$store.dispatch('resetPassword/setIdentityData', this.$route.params.dst);
            this.$store.dispatch('resetPassword/setUsernameReset', this.$route.params.dst);
            this.$store.dispatch('resetPassword/setRecoveryData', this.$route.params.magiclink);


            this.$store.dispatch('resetPassword/checkIdentityRecovery', { 
                type: 'email',
                identity_data: this.$route.params.dst, // viene del path 
                //f"{HTTP_PROTOCOL}://{FQDN}/{API_NAME}/{API_VERSION}/identity/reset/email/{dst_email}/{magic_link}"
                recovery_data: this.$route.params.magiclink, 
                })
        }
    },
    created(){
       this.validateMagicLink();
    }
}
</script>

<style scoped>

</style>

<template>
  <div class="col-md-6 ml-auto mr-auto">
    <form @submit.prevent="goToRecoverPasswordMethods">
      <card class="card-login card-plain">
        <div slot="header">
          <div class="logo-container">
            <img src="img/logo.png" alt="">
          </div>
        </div>

        <div>
        <fg-input v-model="model.username"
                    v-validate="'required'"
                    name="username"
                    :error="getError('username')"
                    class="no-border no-height form-control-lg"
                    :placeholder="t('username')"
                    addon-left-icon="now-ui-icons users_single-02"
                    autocapitalize="none"
                    style="font-size:1rem;">
        </fg-input>
        </div>

        <div slot="footer">
          <n-button native-type="submit" type="primary" round block>
            {{ t('Recover') }}
          </n-button>

          <div class="pull-left">
            <h6>
              <router-link class="link footer-link" to="/forgotmyusername">
                {{ t('Forgot my Username') }}
              </router-link>
            </h6>
          </div> 

          <div class="pull-right">
            <h6>
              <router-link class="link footer-link" to="/login">
                  {{ t('Get Started') }}
              </router-link>
            </h6>
          </div>
          </div>

      </card>
    </form>
  </div>
</template>

<script>
export default {
  name: 'forgotmypassword',
  data() {
    return {
      model: {
        username: ''
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async goToRecoverPasswordMethods(){
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        this.$store.dispatch('resetPassword/setPrevPage', this.$route.path);
        this.$store.dispatch('resetPassword/setRecoveryInfo', this.model.username);
        this.$store.dispatch('resetPassword/setUsernameReset', this.model.username);
        this.$store.dispatch('resetPassword/setRecoveryMethod', 'username');
        this.$store.dispatch('resetPassword/goToRecoverPassword', this.model.username);
      }
    },
  },
  mounted () {
    this.model.username = this.$store.getters["auth/id"];
  }
}
</script>

<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
div.no-height, .no-height {
  height: initial !important
}
</style>

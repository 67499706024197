<template>
    <div class="col-md-6 ml-auto mr-auto">
        <card class="card-login card-plain">

            <div slot="header">
                <div class="logo-container">
                    <img src="img/logo.png" alt="">
                </div>                
            </div>
            <div>
                <h3>Error: {{ error() }}</h3>
            </div>
            <router-link class="link footer-link" :to="this.$store.getters['resetPassword/getPrevPage']">
                <n-button native-type="submit" type="primary" round block >
                    {{ t('Back') }}
                </n-button>
            </router-link>
        </card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                method: '',
                recoveryErrorMessage: ''
            },
            
        }
    },

    mounted () {
        this.model.method = this.$store.getters['resetPassword/getRecoveryMethod'];
    },
    methods:{
        error(){
            // this.model.recoveryErrorMessage = this.$store.getters.getRecoveryErrorMessage;
            return this.$store.getters['resetPassword/getRecoveryErrorMessage'];
            // return this.$store.getters.error;
        },
        getMessage(){
            if (this.model.method == 'sms'){
                return "The verificaction code is not valid."
            }
            else{
                return "This link is invalid."
            }
        }
    }
}
</script>

<style scoped>
    h3{
        color: white !important;
        align-content: center;
        text-align: center;
    }
</style>
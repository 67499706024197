<template>
  <div class="col-md-7 ml-auto mr-auto">
    <form @submit.prevent="login">
      <card class="card-login card-plain">

        <div slot="header">
          <div class="logo-container">
            <img src="img/logo.png" alt="">
          </div>
        </div>

        <div>
          <fg-input v-model="username"
                    autocomplete="username"
                    id="username"
                    v-validate="'required'"
                    name="username"
                    :error="getError('username')"
                    class="no-border no-height form-control-lg"
                    :placeholder="t('username')"
                    addon-left-icon="now-ui-icons users_single-02"
                    autocapitalize="none"
                    style="font-size:1rem;">
          </fg-input>

          <login-password v-model="model_auth" />

        </div>

        <div slot="footer">
          <n-button native-type="submit" type="primary" id="btn_submit" round block>
            {{ t('Get Started') }}
          </n-button>
          <div class="pull-left">
            <h6>
              <router-link id="forgot-password-link" v-if="!model_auth.recoveryLinksDisabled" 
                            class="link footer-link" :to="{ name: 'ForgotMyPassword'}">
                {{ t('Forgot my Password') }}
              </router-link>
            </h6>
          </div>

          <div class="pull-right">
            <h6>
              <router-link id="forgot-username-link" v-if="!model_auth.recoveryLinksDisabled" 
                            class="link footer-link" :to="{ name: 'ForgotMyUsername'}">
                  {{ t('Forgot my Username') }}
              </router-link>
            </h6>
          </div>

        </div>
      </card>
    </form>
  </div>
</template>
<script>

import LoginPassword from "./LoginPassword.vue";
import GeneralMixin from "@/components/AsmConfigurations/general/GeneralMixin";


export default {
  mixins: [GeneralMixin],
  name: 'login',
  components: {
    'login-password': LoginPassword
  },
  data() {
    return {
      username: '',
      model_auth : {
        password: '',
        captcha: '',
        captcha_info: '',
        provider: '',
        recoveryLinksDisabled: false,
        valid: false
      }
    };
  },
  computed: {
    defaultProvider() {
      return this.$store.getters['providers/defaultProvider'];
    },
  },
  methods: {
    async login() {
      let isValidForm = await this.$validator.validateAll();

      if (this.model_auth.valid && isValidForm){
        const payload = {
          username: this.username,
          password: this.model_auth.password,
          captcha: {
            captcha_response: this.model_auth.captcha,
            captcha_hash: this.model_auth.captcha_info.captcha_hash,
          },
          identity_provider_id: this.model_auth.provider === ''
            ? this.defaultProvider
            : this.model_auth.provider
        };
        this.$store.dispatch('auth/login', payload)
          .then( () => {
            this.$router.replace( { path: 'dashboard/general'})
          })
          .catch( err => {
            if( err){
              if ( err.expired)
                this.$router.replace({ name: 'ExpiredPassword'})
              else if ( err.refreshCaptcha){
                this.$store.dispatch('auth/getCaptcha', {});
                this.model_auth.captcha = ''
              }
            }
          });
      }
    },
  },
  mounted() {
    this.username = this.$store.getters["auth/id"];
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
div.no-height, .no-height {
  height: initial !important
}
</style>

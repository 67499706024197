<template>
    <div class="col-md-6 ml-auto mr-auto">
        <form @submit.prevent="validateSMSCode">
            <card class="card-login card-plain">
                <div slot="header">
                    <div class="logo-container">
                        <img src="img/logo.png" alt="">
                    </div>
                </div>

                <fg-input v-model="model.smsCode"
                    v-validate="'required|min:6|max:10'"
                    name="smsCode"
                    :error="getError('smsCode')"
                    class="no-border no-height form-control-lg"
                    :placeholder="t('Code received by SMS')"
                    addon-left-icon="now-ui-icons users_single-02"
                    autocapitalize="none"
                    style="font-size:1rem;">
                </fg-input>

                <div slot="footer">
                    <n-button native-type="submit" type="primary" round block>
                        {{ t('Validate code') }}
                    </n-button>
                </div>

                <div class="pull-right">
                    <h6>
                        <!-- <router-link class="link footer-link" to="/recoverPassword"> -->
                        <!-- <router-link class="link footer-link" to="$router.go(-1)"> -->
                            <a class="link footer-link" href="#" @click="$router.go(-1)">
                            {{ t('Send again') }}
                            </a>
                            <!-- TODO: Revisar: envia a seleccion de metodo aunque venga de forgotUsername -->
                        <!-- </router-link> -->
                    </h6>
                </div>

            </card>
        </form>
    </div>
</template>

<script>
export default {
    name: 'validate-sms-code',
    data() {
        return {
            model: {
                smsCode: ''
            }
        }
    },
    methods:{
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        async validateSMSCode() {
            let isValidForm = await this.$validator.validateAll();
            if (isValidForm) {
                this.$store.dispatch('resetPassword/setRecoveryData', this.model.smsCode);

                this.$store.dispatch('resetPassword/checkIdentityRecovery', {
                    type: this.$store.getters['resetPassword/getRecoveryMethod'], // TODO: ver si es sms o username
                    identity_data: this.$store.getters['resetPassword/recoveryInfo'],
                    //identity_data: this.$store.getters.getUsername,
                    recovery_data: this.model.smsCode
                });
            }
        }
    },    
}
</script>

<style>

</style>

<template>
    <div class="col-md-6 ml-auto mr-auto">
        <form @submit.prevent="recover">
            <card class="card-login card-plain">
                <div slot="header">
                    <div class="logo-container">
                        <img src="img/logo.png" alt="">
                    </div>
                </div>
                <div class="method-radios form-froup text-center">
                    <label for="sms" class="method-radio">
                        <input 
                        type="radio"
                        id="sms"
                        value="sms"
                        v-on:change="clearData()"
                        v-model="model.method">
                        SMS
                    </label>

                    <label for="email" class="method-radio">
                        <input 
                        type="radio"
                        id="email"
                        value="email"
                        v-on:change="clearData()"
                        v-model="model.method">
                        E-mail
                    </label>
                </div>



                <fg-input v-model="model.email" v-if="model.method == 'email'"
                    v-validate="'required|email'"
                    name="e-mail address"
                    :error="getError('e-mail address')"
                    class="no-border no-height form-control-lg"
                    :placeholder="t('Insert your ') + getMethodPlaceholder()"
                    addon-left-icon="now-ui-icons users_single-02"
                    autocapitalize="none"
                    style="font-size:1rem;">
                </fg-input>

                <fg-input v-model="model.sms" v-if="model.method == 'sms'"
                    v-validate="{required:true , regex:/^(\+)?[0-9]+$/}"
                    name="Phone Number"
                    :error="getError('Phone Number')"
                    class="no-border no-height form-control-lg"
                    :placeholder="t('Insert your ') + getMethodPlaceholder()"
                    addon-left-icon="now-ui-icons users_single-02"
                    autocapitalize="none"
                    style="font-size:1rem;">
                </fg-input>

                <div slot="footer">
                    <n-button native-type="submit" type="primary" round block>
                       {{ t('Recover') }}
                    </n-button>
                </div>

            </card>
        </form>
    </div>
</template>


<script>

export default {
    name: 'forgotMyUsername',
    data() {
        return {
            model: {
                recoveryInfo: '',
                method: '',
                sms: '',
                email: ''
            }
        }
    },
    methods:{
        getError(fieldName) {
            return this.errors.first(fieldName);
        },

        async recover() {
            this.$store.dispatch('resetPassword/setPrevPage', this.$route.path);
            let isValidForm = await this.$validator.validateAll();
            if (isValidForm) {
                if (this.model.method == 'sms'){
                    this.model.recoveryInfo = this.model.sms;
                    this.$store.dispatch('resetPassword/setRecoveryInfo', this.model.sms);
                }
                else if (this.model.method == 'email'){
                    this.model.recoveryInfo = this.model.email;
                    this.$store.dispatch('resetPassword/setRecoveryInfo', this.model.email);
                }

                let _vm = this
                this.$store.dispatch('resetPassword/recoverUsernameRequest', {
                    restablish: {
                        method_name: this.model.method, 
                        method_data: this.model.recoveryInfo
                    }
                })
                .then(() => {
                    _vm.$router.push({ name: 'Login'})
                })
                .catch( () => {
                    _vm.$router.push({ name: 'ResetPassword404'})
                })
            }
        },
        getMethodPlaceholder(){

            if (this.model.method == 'sms'){
                return this.t('edit-profile-form.phone')
            }
            else if (this.model.method == 'email'){
                return this.t('edit-profile-form.email')
            }
            else{
                return this.model.method
            }
        },
        clearData(){
            this.model.recoveryInfo = '';
        }
    },
}
</script>

<style scoped>
    .method-radio{
        padding: 25px;
        font-size: 1.25em;
    }
    .method-radios{
        
        color: red;
    }
</style>

<template>
  <div class="col-md-6 ml-auto mr-auto">
    <form @submit.prevent="change">
      <card class="card-login card-plain">
        <div slot="header">
          <div class="logo-container">
            <img src="img/logo.png" alt="">
          </div>
        </div>

        <div>
          <fg-input v-model="model.password"
                    v-validate="'required'"
                    type="password"
                    name="password"
                    :error="getError('password')"
                    class="no-border no-height form-control-lg"
                    placeholder="New Password"
                    addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                    autocapitalize="none"
                    style="font-size:1rem;"
                    ref="password">
          </fg-input>

          <fg-input v-model="model.passwordConfirmation"
                    v-validate="'required|confirmed:password'"
                    type="password"
                    name="passwordConfirmation"
                    :error="getError('passwordConfirmation')"
                    class="no-border no-height form-control-lg"
                    placeholder="Repeat your new Password"
                    addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                    autocapitalize="none"
                    style="font-size:1rem;"
                    data-vv-as="password">
          </fg-input>
        </div>

        <div slot="footer">
          <n-button native-type="submit" type="primary" round block>
            {{ t('Change') }}
          </n-button>

        </div>

      </card>
    </form>
  </div>
</template>

<script>
export default {
  name: 'change-password',
  data() {
    return {
      model: {
        password: ''
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async change(){
      this.$store.dispatch('resetPassword/setPrevPage', this.$route.path);
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        let type = this.$store.getters['resetPassword/getRecoveryMethod']
        let identity_data = null
        if (type == 'username' || type == 'sms')
          identity_data = this.$store.getters['resetPassword/recoveryInfo']
        else if ( type == 'email')
          identity_data = this.$store.getters['resetPassword/getIdentityData']

        this.$store.dispatch('resetPassword/resetPasswordChange', {
            type: type,
            //identity_data: this.$store.getters.recoveryInfo,
            identity_data: identity_data,
            //identity_data: this.$store.getters.getUsername,
            recovery_data: this.$store.getters['resetPassword/getRecoveryData'],
            new_password: this.model.password,
        });
      }
    },
  }
}
</script>

<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
div.no-height, .no-height {
  height: initial !important
}
</style>
